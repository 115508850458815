.site-header {
  display: flex;
  justify-content: space-between; /* Adjusts content to be spaced between */
  align-items: center;
  position: fixed;
  top: 0; /* Ensures the header is always at the top of the viewport */
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--background-color);
  z-index: 1000; /* Ensures the header is above other content */
}
  
  .logo-container {
    display: flex;
    justify-content: flex-start; /* Aligns the logo to the start (left) */
    padding-left: 15px;
  }

  .logo-container img {
    height: 50px; /* Adjust the height as needed */
    width: auto; /* This maintains the aspect ratio */
    /* filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(1%) hue-rotate(42deg) brightness(107%) contrast(101%); */
  }
  
  .button-container {
    position: absolute;
    right: 15px;
  }
  

  .button-container button {
    background-color: transparent; /* Makes the button background transparent */
    border: 1px solid var(--secondary-color); /* Sets the border; adjust color as needed */
    color: var(--secondary-color); /* Sets the text color; match or contrast with border color */
    padding: 8px 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px; /* Adjust text size as needed */
    border-radius: 50px; /* Optional: adds rounded corners */
    text-transform: uppercase; /* Optional: styles the text to uppercase */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .button-container button:hover {
    background-color: var(--secondary-color); /* Optional: fill button on hover */
    color: var(--background-color); /* Change text color on hover for better contrast */
    border-color: var(--secondary-color); /* Ensures the border color stays consistent on hover */
  }
  

  
  @media (max-width: 350px) {
    .logo-container img {
      height: 40px;
      width: auto;
    }
  
}