@import "./colors.css";

@keyframes fadeInGrow {
  from {
    opacity: 0;
    transform: scale(0.95); /* Start a bit smaller */
  }
  to {
    opacity: 1;
    transform: scale(1); /* Scale back to normal size */
  }
}

.fadeInGrowAnimation {
  animation: fadeInGrow 1s ease-out forwards;
}

.App {
  text-align: center;
}


.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

