    /* Reset any top margin/padding on body and ensure the header has accurate, consistent height */
  
    @keyframes fadeInAndScale {
      from {
        opacity: 0;
        transform: translateX(-50%) scale(0.95); /* Start at 90% of the original size */
      }
      to {
        opacity: 1;
        transform: translateX(-50%) scale(1); /* Scale up to 100% of the original size */
      }
    }
  
    body {
    margin: 0;
    padding-top: 70px; /* Adjust this to the header's height if the header is fixed */
  }
  
  .hero-section {
    position: relative;
    width: 100%;
    height: auto; /* Adjust the height as necessary */
    overflow: hidden;
    background-color: var(--background-color);
  }
  
  .hero-image {
    display: block; /* Ensure the image is treated as a block-level element */
    width: 100%; /* Image will scale with the width of its container */
    min-width: 1000px; /* Minimum width of the image */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the area of the container without stretching */
    position: relative; /* Positioned relative to its normal position */
    left: 50%; /* Positioned halfway across its container */
    transform: translateX(-50%) scale(0.95); /* Shift it back to ensure it's centered */
    max-width: none; /* Override any max-width that might be applied elsewhere */
    opacity: 0;
    animation: fadeInAndScale 1s ease-out forwards; 
  }
  