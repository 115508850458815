.footer {
    display: flex;
    justify-content: space-around; /* Equal space between links */
    padding: 15px;
    text-align: center;
    background-color: var(--primary-color);
    font-size: 14px;
  }
  
  .link {
    text-transform: uppercase; /* Make the text uppercase */
    text-decoration: none; /* Remove the default underline */
    color: var(--background-color); /* Set the initial text color */
    padding: 5px; /* Padding for the spacing around the text */
    position: relative; /* Position relative to enable absolute positioning of the pseudo-element */
    transition: color 0.3s ease; /* Smooth transition for the text color */
    overflow: hidden; /* Ensures the pseudo-element does not extend outside */
  }
  
  .link::after {
    content: '';
    position: absolute;
    width: 0; /* Start with no width */
    height: 2px; /* Height of the underline/border */
    bottom: -1px; /* Positioning the underline slightly below the text */
    left: 0;
    background-color: var(--secondary-color); /* Underline/border color */
    transition: width 0.3s ease; /* Smooth transition for the width */
    margin: 0 auto; /* Center the underline */
  }
  
  .link:hover::after {
    width: 100%; /* Full width on hover */
  }
  
  .link:hover {
    color: var(--secondary-color); /* Change text color on hover */
  }
  