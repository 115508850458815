.body-bottom {
    background-color: var(--primary-color);
    padding: 2px;
    margin: auto;
    text-align: center;
}


.text {
    font-size: 10px;
    color: var(--background-color);
}
