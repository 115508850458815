@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.main-section {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding-top: 20px;
    gap: 20px;
    padding-bottom: 20px;
  }
  

  .paragraph {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.1;
    color: var(--text-color);
  }

  .text-container {
    position: relative;
    flex: 1; /* Take up half of the container */
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    background-size: contain; /* Cover the entire section, maintaining aspect ratio */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
  }
  

  .back-image-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); /* Center in the parent */
    width: 100%; /* Or specific size to match TitleSVG */
    height: 100%; /* Or specific size to match TitleSVG */
    background: no-repeat center;
    background-size: cover; /* Adjust as needed */
    z-index: -1; /* Ensure it's behind the TitleSVG */
    width: 100%; /* Adjust based on your SVG's aspect ratio */
    max-width: 800px; /* Adjust based on your preference */
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;     
    /* filter: invert(32%) sepia(84%) saturate(3420%) hue-rotate(352deg) brightness(104%) contrast(87%); */
  }
  
  .title-svg-container {
    position: relative; /* Higher stacking context than back-image-container */
    z-index: 1;
  }

  .title-svg {
    /* display: block; */
    width: 100%; /* Adjust based on your SVG's aspect ratio */
    max-width: 800px; /* Adjust based on your preference */     
    /* filter: invert(32%) sepia(84%) saturate(3420%) hue-rotate(352deg) brightness(104%) contrast(87%); */
    padding-top: 20px;
    padding-bottom: 20px;
    /* opacity: 0; */
    /* animation: fadeIn 1s ease-out forwards; */
  }
  
  .desktop-break {
    display: inline; /* The default display, showing the line break */
  }
  
  .mobile-space {
    display: none; /* Hide the space by default */
  }
  
  .subtitle {
    font-size: 50px; /* Adjust as needed */
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin:0%;
    padding-bottom: 20px;
    line-height: 1.1; /* Adjust line height for better readability */
    color: var(--text-color);
  }

  .highlight {
    color: #4A9E6C;
    font-weight: 600;
  }
  
  .image-container {
    flex: 1; /* Take up the other half of the container */
    position: relative;
    padding-bottom: 50px;

  }
  
  .main-image {
    width: 100%;
    max-width: 1000px; /* Adjust based on your preference */
    right:0px;
    right: 0px; /* This now works because the image is absolutely positioned within a relative container */
    /* opacity: 0; */
    /* animation: fadeIn 1s ease-out forwards; */
  }
  
  @media (max-width: 1270px) {
    .subtitle {
      font-size: 30px;
    }

    .mobile-space {
      display: inline; /* Show the space only on smaller screens */
    }

    .desktop-break {
      display: none; /* Hide the line break on smaller screens */
    }

    .paragraph {
      font-size: 1.5vw;
    }

  }

  @media (max-width: 800px) {
    .main-section {
      flex-direction: column; /* Stack vertically on smaller screens */
      padding-bottom: 20px;
    }

    .text-container {
      text-align: center;
    }
  
    .title-svg, .main-image {
      max-width: 100%; /* Allow images to take full width on smaller screens */
    }

    .mobile-space {
      display: inline; /* Show the space only on smaller screens */
    }

    .desktop-break {
      display: none; /* Hide the line break on smaller screens */
    }

    .subtitle {
      font-size: 30px;
    }

    .paragraph {
      font-size: 15px;
    }


  }