.subscription-section {
  padding-top: 10px;
  padding-bottom: 60px;
  background-color: var(--primary-color); /* Fallback background color *?
  /* background: rgb(241,240,236);
  background: linear-gradient(180deg, rgba(241,240,236,1) 0%, rgba(16,93,56,1) 100%); */
  background-size: contain; /* Cover the entire section, maintaining aspect ratio */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
  border-top-left-radius: 15%;
  border-top-right-radius: 15%;
  text-align: center;
  flex: 1;
}
  
  .subscription-section h1 {
    font-weight: 300;
    color: var(--background-color);
    font-size: clamp(15px, 1.5vw, 30px);
    max-width: 80%;
    margin: auto;
    padding-bottom: 30px;
  }


  .subscription-section h2 {
    margin-bottom: 20px;
    font-weight: 400;
    color: var(--background-color);
    /* white-space: nowrap; Prevents the text from wrapping to a new line */
    max-width: 80%; /* Ensure the element's max width is 100% of its container */
    font-size: clamp(20px, 2.5vw, 40px); /* min-size, scalable size, very large max-size */
    /* text-align: left; */
    padding-top: 60px;
    margin: auto;
    padding-bottom: 10px;
  }

  
  .subscription-section form {
    display: inline-block;
  }
  
  .message-error {
    color: red;
  }
  
  .message-success {
    color: var(--background-color);
  }

  .message-error, .message-success {
    opacity: 0; /* Start fully transparent */
    transition: opacity 2s ease-in-out; /* Smooth transition for the opacity */
    visibility: hidden; /* Initially hide the text to prevent taking up space */
    transition-delay: 0.5s; /* Delay the start of the animation */
  }
  
  /* State when messages are active */
  .message-error.show, .message-success.show {
    opacity: 1; /* Fade to fully opaque */
    visibility: visible; /* Make sure the text is visible and takes up space */
  }

  .text-input {
    font-family: "Poppins", sans-serif;
    padding: 10px 20px; /* Adds padding inside the input box */
    margin: 20px 20px; /* Adds margin around the input box */
    border-radius: 50px; /* Rounds the corners of the input box to match the button */
    font-size: 16px; /* Sets the font size */
    border-width: 0px;
    /* display: inline-block; */
  }
 

  .subscription-section button {
    background-color: transparent; /* Makes the button background transparent */
    border: 1px solid var(--background-color); /* Sets the border; adjust color as needed */
    color: var(--background-color); /* Sets the text color; match or contrast with border color */
    padding: 8px 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px; /* Adjust text size as needed */
    border-radius: 50px; /* Optional: adds rounded corners */
    text-transform: uppercase; /* Optional: styles the text to uppercase */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .subscription-section button:hover {
    background-color: var(--secondary-color); /* Optional: fill button on hover */
    color: var(--background-color); /* Change text color on hover for better contrast */
    border-color: var(--secondary-color); /* Ensures the border color stays consistent on hover */
  }

